.OnboardingProgress {
  margin-bottom: var(--progress-bar-mb, var(--space-24));
  height: 60px;
  position: relative;

  &__Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: repeating-linear-gradient(
    90deg,
    #5748411A,
    #5748411A calc(100% / var(--steps-count) - 8px),
    transparent calc(100% / var(--steps-count) - 8px),
    transparent calc(100% / var(--steps-count))
  );

  :global(.MuiLinearProgress-bar) {
    border-radius: 4px;
    background-image: repeating-linear-gradient(
      90deg,
      #0066FF,
      #0066FF calc(100% / var(--steps-count) - 8px),
      transparent calc(100% / var(--steps-count) - 8px),
      transparent calc(100% / var(--steps-count))
    );
    background-color: transparent;
  }
}
